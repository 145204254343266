var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-media',{staticClass:"mb-2"},[_c('h3',{staticClass:"mb-2"},[_c('span',[_vm._v("Edit Record")]),_c('span',[_vm._v("Save Record")])]),_c('div',{staticClass:"d-flex flex-wrap"},[(_vm.inhabilitar === false)?_c('b-button',{staticClass:"ml-1",attrs:{"variant":_vm.inhabilitar === true ? 'primary' : 'primary'},on:{"click":_vm.saveChanges}},[_c('span',{staticClass:"d-none d-sm-inline"},[_vm._v("Save")]),_c('feather-icon',{staticClass:"d-inline d-sm-none",attrs:{"icon":"TrashIcon"}})],1):_vm._e()],1)]),_c('validation-observer',{ref:"formRules",attrs:{"tag":"form"}},[_c('b-form',{ref:"registerForm"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('b-form-group',{attrs:{"label":"SettingCode"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"disabled":true},model:{value:(_vm.SettingCode),callback:function ($$v) {_vm.SettingCode=$$v},expression:"SettingCode"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v("This field is required")]):_vm._e()]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('b-form-group',{attrs:{"label":"SettingName"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"disabled":_vm.inhabilitar === true},model:{value:(_vm.SettingName),callback:function ($$v) {_vm.SettingName=$$v},expression:"SettingName"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v("This field is required")]):_vm._e()]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('b-form-group',{attrs:{"label":"SettingValue"}},[_c('validation-provider',{attrs:{"rules":"required|integer","name":"SettingValue"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"maxlength":"7","disabled":_vm.inhabilitar === true},model:{value:(_vm.SettingValue),callback:function ($$v) {_vm.SettingValue=$$v},expression:"SettingValue"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('b-form-group',{attrs:{"label":"SettingDistance1"}},[_c('validation-provider',{attrs:{"rules":"required|integer","name":"SettingDistance1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"maxlength":"5","disabled":_vm.inhabilitar === true},model:{value:(_vm.SettingDistance1),callback:function ($$v) {_vm.SettingDistance1=$$v},expression:"SettingDistance1"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('b-form-group',{attrs:{"label":"SettingDistance2"}},[_c('validation-provider',{attrs:{"rules":"required|integer","name":"SettingDistance2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"maxlength":"5","disabled":_vm.inhabilitar === true},model:{value:(_vm.SettingDistance2),callback:function ($$v) {_vm.SettingDistance2=$$v},expression:"SettingDistance2"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('b-form-group',{attrs:{"label":"SettingType"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"maxlength":"40","disabled":true},model:{value:(_vm.SettingType),callback:function ($$v) {_vm.SettingType=$$v},expression:"SettingType"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v("This field is required")]):_vm._e()]}}])})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }